/* Left Menu Styles */
.left-menu {
     width: 20%;
     /* background: #f4f4f4; */
     padding: 10px;
     /* border-right: 1px solid #ddd; */
     box-sizing: border-box;

     /* Prevent word wrapping */
}

.menu-item {
     /* display: inline-block; */
     /* Ensure menu items stay inline */
     padding: 16px 32px;
     cursor: pointer;
     background: #EAEDF2;
     margin-bottom: 10px;
     transition: background 0.3s;
     color: #7D8592;
}

.menu-item:hover {
     background: #1E4387;
     color: white;
}

.menu-item.active {
     background: #1E4387;
     color: white;
     font-weight: bold;
}

/* Right Content Styles */
.right-content {
     padding: 0px 0px 0px 80px;
     width: 80%;

}


/* Responsive Styles */
@media (max-width: 768px) {
     .left-menu {
          width: 100%;
          /* Full width for smaller screens */
          overflow-x: auto;
          white-space: nowrap;
          /* Enable horizontal scrolling */
          border-right: none;
          border-bottom: 1px solid #ddd;
          margin-bottom: 50px;
     }

     .right-content {
          width: 100%;
          box-sizing: border-box;
          padding: 0px;
     }

     .h2-font {
          font-size: 30px !important;
          font-weight: 700 !important;
     }

     .menu-item {
          display: inline-block;
     }

     .flex-important-terms {
          display: block;
     }
}