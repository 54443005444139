.pricing-table {
     width: 100%;
     min-width: 1040px;
     border-collapse: collapse;
     text-align: center;
     font-family: Arial, sans-serif;
}

.pricing-table th,
.pricing-table td {
     padding: 1rem;
     border: none;
}

.pricing-table td:first-child,
.pricing-table th:first-child {
     background-color: transparent;
     color: #333;
     text-align: left;
}

.pricing-table td:last-child,
.pricing-table th:last-child {
     background-color: #f9f9f9;
}

.pricing-table:nth-child(2),
.pricing-table:nth-child(3) {
     border-right: 1px solid red;
}

.pricing-table td:not(:first-child):not(:last-child),
.pricing-table th:not(:first-child):not(:last-child) {
     background-color: #fff;
}

.pricing-table th {
     background-color: #fff;
}

td {
     color: #1e4387;
     font-size: 20px;
     font-weight: 500;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
     .pricing-table-container {
          overflow-x: auto;
     }

     /* .pricing-table th,
     .pricing-table td {
          padding: 0.5rem;
          font-size: 0.9rem;
     } */
}