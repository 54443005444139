@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
     font-family: "Poppins", serif !important;
     font-weight: 400;
     font-size: 16px;
     line-height: 24px;
     overflow: hidden;
     overflow-y: auto;
}

a {
     text-decoration: none;
}

/* ***************************************************************************new added start**************************************************************************************** */

.doted-border {
     width: auto;
     height: 1px;
     top: 100%;
     left: 0;
     border-bottom: 1px dashed #e2e4e9;
     margin: 40px 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     margin: 0;
}

input {
     outline: none;
     font-size: 16px;
     padding: 10px;
     caret-color: #848484;
}

textarea {
     resize: none;
     outline: none;
}

.box-shadows {
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* ***************************************************************************new added end**************************************************************************************** */
@media (min-width: 1400px) {

     .container,
     .container-lg,
     .container-md,
     .container-sm,
     .container-xl,
     .container-xxl {
          max-width: 1620px !important;
     }
}

.website-container {
     --spacing: 20px;
     --container-width: 1600px;
     max-width: calc(var(--container-width) + var(--spacing) * 2);
     padding: 16px var(--spacing);
     margin: auto;
     width: 100%;
}

@media only screen and (min-width: 1200px) {
     .website-container {
          --spacing: 100px;
          --container-width: 1600px;
          max-width: calc(var(--container-width) + var(--spacing) * 2);
          padding: 16px var(--spacing);
          margin: auto;
          width: 100%;
     }
}

@media only screen and (max-width: 602px) {
     .flex-important {
          /* display: block !important; */
          flex-direction: column-reverse;
          align-items: center;
     }
}

.faq-dots {
     list-style-type: disc;
}

.dropdown-menu[data-bs-popper] {
     top: 157% !important;
}

.dropdown-menu {
     border: 0 !important;
     box-shadow: rgba(100, 100, 111, 0.3) 0px -10px 20px, rgba(100, 100, 111, 0.3) 0px 10px 20px, rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
     border-radius: 1rem;
}

.dropdown-new {
     position: relative;
}

.pyramid {
     width: 0;
     height: 0;
     border-left: 18px solid transparent;
     border-right: 18px solid transparent;
     border-bottom: 36px solid #fff;
     /* Color of the pyramid */
     position: absolute;
     bottom: 93%;
     left: 20%;
}

.pyramid-two {
     width: 0;
     height: 0;
     border-left: 18px solid transparent;
     border-right: 18px solid transparent;
     border-bottom: 36px solid #fff;
     /* Color of the pyramid */
     position: absolute;
     bottom: 97%;
     left: 20%;
}

.popular::after {
     border-right: 18px solid transparent;
     border-top: 18px solid #08204b;
     bottom: -18px;
     content: "";
     position: absolute;
     right: 0;
}

.row.row-100 {
     margin-left: -50px;
     margin-right: -50px;
}

.row.row-100 .col-lg-6 {
     padding-left: 50px;
     padding-right: 50px;
}

h1 {
     font-size: 40px;
     /* line-height: 72px; */
     font-weight: 600;
     color: #191b1c;
}

h2 {
     font-size: 40px;
     /* line-height: 64px; */
     font-weight: 700;
     color: #191b1c;
     margin: 0 0 20px;
}

h3 {
     font-size: 40px;
     line-height: 64px;
     font-weight: 600;
     color: #191b1c;
     margin: 0 0 20px;
}

@media only screen and (max-width: 1440px) {
     h1 {
          font-size: 64px;
          line-height: 72px;
     }

     h2 {
          font-size: 64px;
          line-height: 64px;
     }

     h3 {
          font-size: 64px;
          line-height: 64px;
     }
}

@media only screen and (max-width: 768px) {
     h1 {
          font-size: 30px;
          line-height: normal;
     }

     h2 {
          font-size: 30px;
          line-height: normal;
     }

     h3 {
          font-size: 30px;
          line-height: normal;
     }

     h4 {
          font-size: 24px;
     }

     h5 {
          font-size: 24px;
          line-height: 32px;
     }
}

@media only screen and (max-width: 425px) {
     h1 {
          font-size: 20px;
          line-height: normal;
     }

     h2 {
          font-size: 24px;
          line-height: normal;
     }

     h3 {
          font-size: 24px;
          line-height: normal;
     }

     h4 {
          font-size: 24px;
     }

     h5 {
          font-size: 24px;
          line-height: 32px;
     }
}

h4 {
     font-size: 32px;
     font-weight: 600;
     color: #191b1c;
}

h5 {
     font-size: 20px;
     line-height: 32px;
     font-weight: 600;
     color: #191b1c;
}

.primary-color {
     color: #0bafd4;
}

.secondary-color {
     color: #1e4387;
}

p {
     color: #7d8592;
     line-height: 26px;
}

.gray {
     color: #7d8592;
}

img {
     max-width: 100%;
}

.bg-color {
     background: #eaecf3;
}

.fw-500 {
     font-weight: 500;
}

.fw-600 {
     font-weight: 600;
}

.fw-700 {
     font-weight: 700;
}

.mt-50 {
     margin-top: 50px;
}

.mb-12 {
     margin-bottom: 12px;
}

.mb-20 {
     margin-bottom: 20px;
}

.mb-30 {
     margin-bottom: 30px;
}

.mb-50 {
     margin-bottom: 50px;
}

.mb-60 {
     margin-bottom: 60px;
}

.mb-80 {
     margin-bottom: 80px;
}

.mb-100 {
     margin-bottom: 100px;
}

.mb-110 {
     margin-bottom: 110px;
}

.mt-100 {
     margin-top: 100px;
}

.mt-60 {
     margin-top: 60px;
}

.pb-60 {
     padding-bottom: 60px;
}

.mr-20 {
     margin-right: 20px;
}

.f-20 {
     font-size: 20px;
}

.f-18 {
     font-size: 16px;
}

.min-72 {
     min-height: 72px;
}

.col-xl-pdleft78 {
     padding-left: 48px;
}

.col-xl-pdleft30 {
     padding-left: 30px;
}

.link {
     font-size: 16px;
     color: #1e4387;
     font-weight: 500;
     display: inline-block;
     cursor: pointer;
     text-decoration: none;
}

.navbar-toggler:focus {
     box-shadow: none;
}

.mx-1180 {
     max-width: 1180px;
     margin-left: auto;
     margin-right: auto;
}

.mx-1020 {
     max-width: 1020px;
     margin-left: auto;
     margin-right: auto;
}

.mx-707 {
     max-width: 707px;
     margin-left: auto;
     margin-right: auto;
}

.mx-588 {
     max-width: 588px;
     margin-left: auto;
     margin-right: auto;
}

.mx-515 {
     max-width: 515px;
}

.btn {
     font-size: 16px;
     font-weight: 600;
     padding: 12px 38px;
     border-radius: 12px;
     min-width: 150px;
}

.btn-primary {
     background-color: #1e4387;
     border-color: #1e4387;
}

:not(.btn-check)+.btn.btn-primary:active,
.btn.btn-primary:first-child:active,
.btn-primary:focus,
.btn-primary:hover {
     background-color: #1e2a87;
     border-color: #1e2a87;
}

.btn-primary-other:active,
.btn-primary-other:hover,
.btn-primary-other:focus,
.btn-primary-other {
     color: #fff;
     background-color: #0bafd4;
     border-color: #0bafd4;
}

.btn-outline-primary {
     border-color: #1e4387;
     color: #1e4387;
}

.btn.btn-outline-primary:first-child:active,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
     background-color: #1e4387;
     border-color: #1e4387;
     color: #fff;
}

.min-height-48 {
     min-height: 48px;
}

.highlite {
     font-weight: 600;
     color: #0bafd4;
     margin: 0 0 20px;
}

.box-min-height-120 {
     min-height: 120px;
}

.min-height-557 {
     min-height: 557px;
}

.min-height-850 {
     min-height: 850px;
     display: flex;
     align-items: center;
     justify-content: center;
}

.min-height-750 {
     min-height: 750px;
     display: flex;
     align-items: center;
     justify-content: center;
}

.min-height-650 {
     min-height: 650px;
     display: flex;
     align-items: center;
     justify-content: center;
}

.radius-24 {
     border-radius: 24px;
}

.mx-640 {
     max-width: 640px;
     margin-left: auto;
     margin-right: auto;
}

.mx-700 {
     max-width: 700px;
}

.common-margin {
     margin-top: 60px;
     margin-bottom: 60px;
}

.common-padding {
     padding: 60px 0;
}

.common-padding-top {
     padding-top: 50px;
}

.common-diff-margin {
     margin-top: 150px;
     margin-bottom: 268px;
}

.navbar {
     padding-top: 25px !important;
     padding-bottom: 25px !important;
}

.navbar .nav-link {
     color: #7d8592 !important;
     font-weight: 500;
     font-size: 16px;
     margin: 0;
     padding: 10px 20px !important;
}

.navbar .nav-link:hover {
     color: #1e4387;
}

.hero-section-pd-r0 {
     padding: 75px 0 75px 7%;
}

/* .hero-section {
     background: #fafafa;
     overflow: hidden;
} */
.pattern-line {
     background-image: url("./assets/img/herosection.png");
     width: 100%;
     height: 100vh;
     background-size: cover;
}

.hero-section .hero-picture {
     position: relative;
}

.hero-section .hero-picture .second-img {
     position: absolute;
     bottom: -70px;
     left: -70px;
     z-index: 1;
}

.hero-section .__text {
     position: relative;
}

.hero-section .hero-icon {
     position: absolute;
     z-index: 1;
}

.hero-section .hero-icon.heroiconlefttop {
     left: 77px;
     top: 40%;
}

.hero-section .hero-icon.heroiconrighttop {
     right: 79px;
     top: 40%;
}

.hero-section .hero-icon.heroiconleftmiddle {
     left: -43px;
     top: 14%;
}

.hero-section .hero-icon.heroiconrightmiddle {
     right: -42px;
     top: 14%;
}

.combine-picture {
     position: relative;
}

.combine-picture .second-img {
     position: absolute;
     bottom: -70px;
     right: 20px;
     z-index: 1;
}

.anual-report img {
     max-width: 685px;
}

.dropdown-toggle::after {
     background: url("../src/assets/img/arrow-down.svg") no-repeat center center;
     width: 13px;
     height: 8px;
     border: 0;
     position: relative;
     top: 3px;
}

.trusted-list .box {
     margin: 0 0 60px;
}

.trusted-list .box .picture {
     display: block;
     position: relative;
}

.trusted-list .box .picture:before {
     content: "";
     display: block;
     padding-top: 170px;
}

.trusted-list .box .picture img {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     max-width: 100%;
     max-height: 100%;
     margin: auto;
     -webkit-transition: all 0.3s ease;
     transition: all 0.3s ease;
     -webkit-transform: translateZ(0) scale(1, 1);
}

.trusted-list .box .picture.opacity-low img {
     opacity: 0.5;
}

.customization {
     background: rgba(30, 67, 135, 0.05);
}

.customization .box {
     background: #fff;
     border-radius: 24px;
     padding: 40px;
     margin-bottom: 30px;
}

.customization .box .d-flex {
     align-items: center;
}

.customization .box .icon {
     align-items: center;
     justify-content: center;
     width: 64px;
     height: 64px;
     background: #eaedf2;
     border-radius: 180px;
     min-width: 64px;
}

.customization .box p {
     font-size: 16px;
     color: #7d8592;
     margin: 0;
     line-height: 24px;
}

.counting-box {
     border-radius: 24px;
     box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.07);
     padding: 31px 20px;
}

.counting-box p {
     color: #191b1c;
}

.how-trackmydoc a {
     display: inline-block;
}

.how-trackmydoc ._picture {
     position: relative;
}

.how-trackmydoc ._picture .tooltip-popup {
     background: #eaedf2;
     z-index: 1;
     border-radius: 24px;
     padding: 15px;
     display: flex;
     position: absolute;
     left: -10%;
     bottom: 5px;
     border: 0.5px solid rgba(30, 67, 135, 1);
     align-items: center;
}

.how-trackmydoc ._picture .tooltip-popup ._right {
     border-left: 1px solid #191b1c;
     margin-left: 20px;
     padding-left: 20px;
}

.integrations-section {
     margin-top: 118px;
}

.shadow-box {
     border-radius: 24px;
     box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.07);
     padding: 30px;
     margin: 0 0 40px;
}

.shadow-box.shadow-0 {
     box-shadow: none;
     padding: 0;
     margin: 0 0 60px;
}

.blue-box {
     border-radius: 24px;
     box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.07);
     padding: 48px 10px;
     margin: 0 0 24px;
     background: #1e4387;
}

.blue-box h4 {
     color: #fff;
     font-size: 64px;
}

.blue-box p {
     color: #fff;
     line-height: 24px;
     max-width: 195px;
     margin: 0 auto;
}

.blue-box p.mx-230 {
     max-width: 230px;
}

.feature-box {
     padding: 57px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
}

.feature-box p {
     max-width: 402px;
     margin-left: auto;
     margin-right: auto;
}

.step {
     max-width: 800px;
     margin-left: auto;
     margin-right: auto;
}

.step ul {
     margin: 0;
     padding: 0;
     list-style: none;
     display: flex;
}

.step ul li {
     position: relative;
     width: 25%;
}

.step ul li:before {
     content: "";
     border-top: 2px dashed #0bafd4;
     width: 100%;
     height: 2px;
     position: absolute;
     left: 50%;
     top: 30px;
}

.step ul li:last-of-type:before {
     display: none;
}

.step ul li span {
     position: relative;
     z-index: 1;
     background: #d0daea;
     border-radius: 180px;
     width: 60px;
     height: 60px;
     border-radius: 180px;
     font-weight: 600;
     color: #1e4387;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 34px;
     margin-left: auto;
     margin-right: auto;
}

.step ul li b {
     font-size: 16px;
     color: #1e4387;
     font-weight: 600;
     display: block;
     text-align: center;
}

.list-ui {
     list-style: none;
     margin: 0;
     padding: 0;
}

.list-ui:after {
     content: "";
     clear: both;
     display: block;
}

.list-ui li {
     position: relative;
     font-size: 20px;
     color: #191b1c;
     font-weight: 500;
     padding-left: 28px;
     margin: 0 0 30px;
}

.list-ui li:before {
     content: "";
     background: url("assets/img/checked.svg") no-repeat center center;
     display: inline-block;
     position: absolute;
     left: 0;
     top: 2px;
     width: 18px;
     height: 18px;
}

.list-50 li {
     display: inline-block;
     width: 50%;
     float: left;
}

.accordion {
     max-width: 1120px;
     margin-left: auto;
     margin-right: auto;
}

.accordion-header {
     line-height: 1;
}

.accordion-item {
     margin: 0 0 20px;
     border: none;
     background: #fff;
     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
     border-radius: 12px;
}

.mb-01 .accordion-item {
     margin-bottom: 12px;
}

.customizable-user {
     background: #1e4387;
     padding: 100px 100px 100px 160px;
     border-radius: 24px;
     position: relative;
     margin-left: 400px;
}

.customizable-user h4 {
     color: #fff;
}

.customizable-user h5,
.customizable-user .sub-div {
     color: #fff;
     display: inline-block;
}

.customizable-user img {
     position: absolute;
     top: 50%;
     left: -400px;
     margin-top: -181px;
}

.accordion-button:focus {
     box-shadow: none;
}

.accordion-collapse {
     border-radius: 0px 0px 12px 12px;
}

.accordion-header .accordion-button.collapsed {
     border-radius: 12px;
}

.accordion-button {
     border-radius: 12px 12px 0 0;
     font-weight: 600;
     color: #191b1c !important;
     padding: 20px 32px;
     font-size: 16px;
     line-height: 20px;
}

.accordion-button:not(.collapsed),
.accordion-collapse.collapse.show {
     background-color: #eaedf2;
     box-shadow: none;
}

.accordion-body {
     padding: 0 32px 20px;
     color: #7d8592;
     background-color: #eaedf2;
     border-radius: 0 0 12px 12px;
     padding-right: 110px;
}

.accordion-button::after {
     background-color: #eaedf2;
     border-radius: 8px;
     width: 32px;
     height: 32px;
     background-position: center;
}

.accordion-button:not(.collapsed)::after {
     background-color: #fff;
}

footer {
     background: #1a284e;
     padding: 60px 0 0;
}

.footer-list {
     margin-bottom: 40px;
}

footer .title {
     font-size: 24px;
     color: #0bafd4;
     font-weight: 700;
     margin: 0 0 20px;
}

footer ul {
     list-style: none;
     margin: 0;
     padding: 0;
}

footer ul li {
     margin: 12px 0;
}

footer ul li a {
     color: #e2e4e9 !important;
     line-height: 24px;
     display: inline-block;
     font-size: 14px;
     cursor: pointer;
}

footer ul li.more {
     margin-top: 20px;
}

footer ul li.more a {
     text-decoration: underline !important;
}

footer .app p {
     font-size: 16px;
     font-weight: 600;
     color: #e2e4e9;
     margin: 60px 0 20px;
}

footer .app a {
     display: inline-block;
}

.footer-bottom {
     margin-top: 20px;
     background: #142144;
     padding: 29px 0;
}

.footer-bottom p {
     margin: 0;
     color: #fffcf7;
     font-weight: 400;
     font-size: 14px;
     line-height: 24px;
}

.social-media {
     list-style: none;
     margin: 0;
     padding: 0;
}

.social-media li {
     display: inline-block;
     margin-left: 15px;
}

.social-media li a {
     display: inline-block;
     cursor: pointer;
}

.customer-review-box {
     list-style-type: none;
     margin: 0;
     padding: 0;
     display: flex;
     align-items: self-start;
}

.customer-review-box li {
     box-shadow: 3.4px 6.8px 13.6px 0px rgba(0, 0, 0, 0.05);
     padding: 33px;
     border-radius: 20px;
     position: relative;
     z-index: 1;
     background-color: #fff;
}

.customer-review-box li p {
     color: #7d8592;
     font-size: 14px;
     line-height: 21px;
     margin: 10px 0 17px;
}

.customer-review-box li b {
     font-size: 14px;
     color: #191b1c;
     font-weight: 600;
     display: block;
     margin: 0 0 1px;
     line-height: 1;
}

.customer-review-box li span {
     color: #7d8592;
     font-size: 12px;
}

.customer-review-box li .star {
     padding-bottom: 30px;
}

.customer-review-box li .star img {
     margin: 0 2px;
}

.customer-review-box li .review-img {
     position: absolute;
     bottom: -38px;
     left: 50%;
     margin-left: -38px;
}

.customer-review-box li.mtop-70 {
     margin-top: 70px;
     z-index: 0;
}

.customer-review-box li.mtop-190 {
     margin-top: 190px;
     z-index: 0;
}

.customer-review-box li.left15 {
     left: 15px;
}

.customer-review-box li.right15 {
     right: 15px;
}

.firm-box {
     background: rgba(30, 67, 135, 0.05);
     border-radius: 24px;
     padding: 40px;
}

.firm-box a {
     text-decoration: none;
}

.firm-box.mtop-100 {
     margin-top: 100px;
}

.testimonials {
     background-color: #fff;
     padding: 40px;
     border-radius: 24px;
     position: relative;
     opacity: 0.5;
     box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.05);
}

.slick-center.slick-current .testimonials {
     opacity: 1;
}

.testimonials ._right {
     min-width: 158px;
}

.testimonials ._right .avt {
     margin-left: auto;
     margin-right: auto;
}

.testimonials ._right b {
     display: block;
     font-size: 16px;
     font-weight: 600;
     color: #191b1c;
     margin: 0 0 4px;
}

.testimonials ._right span {
     display: block;
     font-size: 12px;
     font-weight: 400;
     color: #7d8592;
     margin: 0 0 4px;
}

.testimonials ._right .star {
     justify-content: center;
}

.testimonials ._right .star img {
     margin: 0 3px;
}

.slick-dots {
     bottom: -60px;
}

.slick-dots li {
     width: 50px;
     margin: 0 10px;
}

.slick-dots li button {}

.slick-dots li button:before {
     background-color: #8b8b8b;
     width: 50px;
     height: 5px;
     font-size: 0;
     display: inline-block;
     border-radius: 24px;
}

.slick-dots li.slick-active button:before {
     background-color: #1e4387;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
     white-space: nowrap;
}

.dropdown-menu .nav-link {
     margin: 7px 15px;
     font-size: 16px;
}

.col-xl-pdright {
     padding-right: 50px;
}

@media (min-width: 1025px) and (max-width: 1440px) {
     h1 {
          font-size: 48px;
          line-height: 68px;
     }

     h2 {
          font-size: 40px;
          line-height: 50px;
     }

     h5 {
          font-size: 20px;
          line-height: 26px;
     }

     .customization .box {
          padding: 30px;
     }

     .col-xl-pdright {
          padding-right: 50px;
     }

     .blue-box h4 {
          font-size: 50px;
     }
}

@media (min-width: 992px) and (max-width: 1024px) {
     h1 {
          font-size: 42px;
          line-height: 52px;
     }

     h2 {
          font-size: 38px;
          line-height: 45px;
     }

     h3 {
          font-size: 30px;
          line-height: 44px;
     }

     h5 {
          font-size: 20px;
          line-height: 26px;
     }

     .col-xl-pdright {
          padding-right: 0;
     }

     .min-height-48 {
          min-height: 72px;
     }

     .common-padding {
          padding: 70px 0;
     }

     .common-margin {
          margin-top: 70px;
          margin-bottom: 70px;
     }

     .common-padding-top {
          padding-top: 70px;
     }

     .customization .box {
          padding: 30px;
     }

     .common-diff-margin {
          margin-top: 80px;
          margin-bottom: 120px;
     }

     .min-height-557 {
          min-height: 596px;
     }

     .customizable-user {
          padding: 70px 70px 70px 120px;
     }

     .firm-box {
          padding: 20px;
     }

     .blue-box h4 {
          font-size: 50px;
     }

     .list-ui li {
          font-size: 17px;
     }

     .anual-report img {
          max-width: 100%;
     }

     .navbar .nav-link {
          font-size: 17px;
          padding: 10px 10px !important;
     }

     .navbar .btn {
          padding: 12px 20px;
          min-width: none;
     }

     .mr-20 {
          margin-right: 10px;
     }

     .box-min-height-120 {
          min-height: 218px;
     }
}

@media (max-width: 1024px) {
     .testimonials {
          opacity: 1;
     }
}

@media (min-width: 992px) {
     .hero-section-pd-r0 .container-fluid {
          padding-right: 0;
     }
}

@media (max-width: 1400px) {
     .hero-section .hero-icon {
          display: none;
     }
}

@media (max-width: 991px) {
     /* h1 {
          font-size: 35px;
          line-height: 42px;
     }

     h2 {
          font-size: 30px;
          line-height: 38px;
     }

     h3 {
          font-size: 28px;
          line-height: 34px;
     } */

     h4 {
          font-size: 24px;
     }

     h5 {
          font-size: 16px;
          line-height: 24px;
     }

     .res-mb-40 {
          margin-bottom: 40px;
     }

     .mb-80 {
          margin-bottom: 45px;
     }

     .mb-30 {
          margin-bottom: 20px;
     }

     .f-18 {
          font-size: 16px;
     }

     .f-20 {
          font-size: 18px;
     }

     .mt-50 {
          margin-top: 35px;
     }

     .mb-60 {
          margin-bottom: 40px;
     }

     .col-xl-pdright {
          padding-right: 0;
     }

     .common-padding-top {
          padding-top: 60px;
     }

     .common-diff-margin {
          margin-top: 60px;
          margin-bottom: 80px;
     }

     .customizable-user img {
          position: relative;
          top: 0;
          left: 0;
          margin: 0 0 40px;
     }

     .customizable-user {
          margin: 0;
          padding: 50px;
     }

     .list-50 li {
          width: 100%;
     }

     .navbar .navbar-collapse {
          border: 1px solid #dedede;
          padding: 20px;
          margin: 20px 0 0;
          border-radius: 5px;
     }

     .navbar .nav-link {
          margin: 10px 0;
          font-size: 18px;
     }

     .navbar-nav.navbar-nav-scroll {
          margin: 0 0 20px !important;
     }

     .btn {
          padding-left: 20px;
          padding-right: 20px;
          font-size: 14px;
     }

     .hero-section-pd-r0 {
          padding: 50px 0;
     }

     .hero-section .hero-picture .second-img {
          max-width: 150px;
     }

     .hero-section .hero-picture .second-img {
          left: -20px;
     }

     .accordion-body {
          padding-right: 70px;
          font-size: 15px;
     }

     .counting-box {
          padding: 15px;
     }

     .counting-box p {
          line-height: 1.2;
     }

     .how-trackmydoc ._picture .tooltip-popup {
          left: 0;
          bottom: 0;
     }

     .shadow-box {
          margin-bottom: 30px;
          padding: 25px;
     }

     .anual-report img {
          max-width: 100%;
     }

     .common-margin {
          margin-top: 60px;
          margin-bottom: 60px;
     }

     .common-padding {
          padding: 60px 0;
     }

     .trusted-list .box .picture:before {
          padding-top: 100px;
     }

     .accordion.me-5 {
          margin-right: 0 !important;
     }

     .combine-picture .second-img {
          max-width: 110px;
     }

     .blue-box {
          padding: 15px;
     }

     .blue-box h4 {
          font-size: 35px;
     }

     .blue-box p {
          line-height: 1.2;
     }

     .min-height-557,
     .min-height-650,
     .min-height-850,
     .min-height-750 {
          min-height: auto;
     }
}

@media (max-width: 767px) {
     .step ul li span {
          width: 40px;
          height: 40px;
          font-size: 21px;
     }

     .step ul li:before {
          top: 21px;
     }

     .step ul li b {
          font-size: 14px;
          line-height: 1.2;
     }

     .list-ui li {
          font-size: 18px;
          margin-bottom: 20px;
     }

     .firm-box.mtop-100 {
          margin-top: 30px;
     }

     .customer-review-box {
          display: block;
     }

     .customer-review-box li {
          margin-bottom: 60px;
     }

     .customer-review-box li:last-of-type {
          margin-bottom: 30px;
     }

     .customer-review-box li.mtop-190,
     .customer-review-box li.mtop-70 {
          margin-top: 0;
          z-index: 0;
     }

     .customer-review-box li.left15 {
          left: 0;
     }

     .customer-review-box li.right15 {
          right: 0;
     }

     .mr-20 {
          margin-right: 10px;
     }

     footer .title {
          font-size: 20px;
     }

     footer ul li a {
          font-size: 15px;
     }

     footer .app p {
          margin: 30px 0 20px;
     }

     .social-media {
          margin-top: 7px;
     }

     .social-media li {
          margin-left: 0;
          margin-right: 15px;
     }

     .footer-bottom p {
          font-size: 14px;
     }

     .testimonials {
          padding: 20px;
     }

     .testimonials ._left {
          margin: 0 0 15px;
     }
}

@media (max-width: 480px) {
     .navbar .d-flex .btn {
          width: 50%;
          min-width: auto;
          padding-left: 0;
          padding-right: 0;
     }

     .navbar .nav-link {
          padding-left: 0 !important;
          padding-right: 0 !important;
     }

     .navbar-nav .dropdown-menu {
          padding: 0 15px;
     }

     .btn.btn-primary-other {
          padding-left: 0 !important;
          padding-right: 0 !important;
          min-width: 100%;
     }
}